import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      gapCertificate: file(
        relativePath: { eq: "pdf/Global-Gap-Certificate.pdf" }
      ) {
        publicURL
      }
      hoptechnicCertificate: file(
        relativePath: { eq: "pdf/Hoptechnic-Exp.pdf" }
      ) {
        publicURL
      }
      salmonCertificate: file(
        relativePath: { eq: "pdf/Salmon-Safe-Cert.pdf" }
      ) {
        publicURL
      }
      kosherCertificate: file(
        relativePath: { eq: "pdf/VGF-Kosher-Certificate.pdf" }
      ) {
        publicURL
      }
    }
  `);
    return data;
};
