import { useStaticQuery, graphql } from "gatsby";
const useGetContactBoxIcon = () => {
    const { emailIcon, fbIcon, instaIcon } = useStaticQuery(graphql `
    query {
      fbIcon: file(relativePath: { eq: "images/facebook.svg" }) {
        publicURL
      }
      instaIcon: file(relativePath: { eq: "images/instagram.svg" }) {
        publicURL
      }
      emailIcon: file(relativePath: { eq: "images/mail.svg" }) {
        publicURL
      }
    }
  `);
    return {
        emailIcon: emailIcon.publicURL,
        fbIcon: fbIcon.publicURL,
        instaIcon: instaIcon.publicURL,
    };
};
export default useGetContactBoxIcon;
