import React from "react";
import classnames from "classnames";
import s from "./Burger.module.scss";
const Burger = ({ handleStateChange, menuOpen }) => {
    const handleClick = (e) => {
        handleStateChange();
    };
    const styles = classnames(s.wrap, { [s.open]: menuOpen });
    return (<div className={styles} onClick={handleClick}>
      <span />
      <span />
      <span />
    </div>);
};
export default Burger;
