import React, { useState } from "react";
import classnames from "classnames";
import useGetImages from "./useGetImages";
import cardData from "./cardData";
import ModalWindow from "../../../../scenes/bestPractices/components/modalWindow";
import s from "./CertificatesBox.module.scss";
import useGetCertificates from "./useGetCertificates";
const CertificatesBox = ({ isCertificatesOpen, handleCertificatesClick, }) => {
    const { salmonSafeImage, globalGapImage, fdaImage, certifiedOrganicImage, kosherImage, hoptech, worldwideImage, truePellet, } = useGetImages();
    const { gapCertificate, hoptechnicCertificate, salmonCertificate, kosherCertificate, } = useGetCertificates();
    const [modalContentInx, setModalContentInx] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleClick = e => {
        handleOpenModal(e.target.getAttribute("data-id"));
    };
    const handleOpenModal = index => {
        setModalIsOpen(true);
        setModalContentInx(index);
    };
    const data = [
        {
            description: "Global G.A.P.",
            title: "Global G.A.P. Certification",
            imageUrl: globalGapImage,
            modalContent: cardData[1],
            modalIcon: globalGapImage,
            certificateLink: gapCertificate.publicURL
        },
        {
            title: "Certified Organic",
            description: "Certified Organic",
            imageUrl: certifiedOrganicImage,
            modalContent: cardData[3],
            modalIcon: certifiedOrganicImage,
            certificateLink: ""
        },
        {
            title: "Salmon Safe",
            description: "Salmon Safe",
            imageUrl: salmonSafeImage,
            modalContent: cardData[0],
            modalIcon: salmonSafeImage,
            certificateLink: salmonCertificate.publicURL
        },
        {
            title: "Kosher",
            description: "Kosher",
            imageUrl: kosherImage,
            modalContent: cardData[4],
            modalIcon: kosherImage,
            certificateLink: kosherCertificate.publicURL
        },
        {
            title: "HOPTECHNICS®",
            description: "HOPTECHNICS®",
            imageUrl: hoptech,
            modalContent: cardData[6],
            modalIcon: hoptech,
            certificateLink: hoptechnicCertificate.publicURL
        },
    ];
    const styles = classnames(s.box);
    const modalButtonStyles = classnames(s.certificatesButton, {
        [s.modalOpen]: isCertificatesOpen,
        [s.buttonIndex]: modalIsOpen,
    });
    const abrirNuevaPestana = (certificateLink) => {
        if (certificateLink) {
            window.open(certificateLink, "_blank");
        }
    };
    return (<>
      {!modalIsOpen && (<div className={modalButtonStyles} onClick={handleCertificatesClick}>
          {isCertificatesOpen ? "CLOSE" : "CERTIFICATIONS"}
        </div>)}

      {!modalIsOpen && isCertificatesOpen && (<div className={s.wrap}>
          <>
            <div className={s.title}>CERTIFICATIONS</div>
            <div className={styles}>
              {data.map(({ title, description, imageUrl, certificateLink }, i) => (<div className={s.item} key={title}>
                  <img data-id={i} className={s.image} src={imageUrl.childImageSharp.fluid.src} alt={title} onClick={() => abrirNuevaPestana(certificateLink)}/>
                  <div className={s.itemTitle}>{description}</div>
                  {title === "HOPTECHNICS®" ? (<a href="https://hoptechnic.com" target="_blank" className={s.itemButton}>
                      MORE INFO
                    </a>) : title === "TRUE PELLET" ? (<a href="https://hoptechnic.com/truepellet" target="_blank" className={s.itemButton}>
                      MORE INFO
                    </a>) : (<button className={s.itemButton} data-id={i} onClick={handleClick}>
                      MORE INFO
                    </button>)}
                </div>))}
            </div>
          </>
        </div>)}
      <ModalWindow open={modalIsOpen} title={data[modalContentInx].title} modalContent={data[modalContentInx].modalContent} modalIcon={data[modalContentInx].modalIcon} closeModal={setModalIsOpen}/>
    </>);
};
export default CertificatesBox;
