import React from "react";
import Popup from "reactjs-popup";
import s from "./ModalWindow.module.scss";
const ModalWindow = ({ modalIcon, modalContent: Content, title, closeModal, open, }) => {
    const handleClose = () => {
        closeModal(false);
    };
    return (<Popup className={s.modal} open={open} modal closeOnDocumentClick onClose={handleClose} lockScroll overlayStyle={{
        overflowY: "auto",
    }}>
      <div className={s.box}>
        <div className={s.cross} onClick={handleClose}>
          <span className={s.crossItem}></span>
          <span className={s.crossItem}></span>
        </div>
        <img className={s.img} src={modalIcon.childImageSharp.fluid.src} alt={title}/>
        <h3 className={s.title}>{title}</h3>
        <div>
          <Content />
        </div>
        <button onClick={handleClose} className={s.btn}>
          back
        </button>
      </div>
    </Popup>);
};
export default ModalWindow;
