export const links = [
    {
        link: "/",
        label: "Home",
    },
    {
        link: "/hop-varieties",
        label: "Hop Varieties and Products",
    },
    {
        link: "/hops-for-sale",
        label: "Hop Purchasing and Distribution",
    },
    {
        link: "/farm-history",
        label: "Our Story",
    },
    {
        link: "/best-practices",
        label: "Core Processes",
    },
    {
        link: "/auxiliary-growers",
        label: "Auxiliary Grower Program",
    },
    {
        link: "/growing-hops",
        label: "Current Innovations",
    },
    {
        link: "https://amarillohop.com",
        label: "Amarillo®",
    },
    {
        link: "https://hoptechnic.com",
        label: "HopTechnic®",
    },
    {
        link: "/contacts",
        label: "Contact Us",
    },
];
