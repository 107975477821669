import React from "react";
import classnames from "classnames";
import { ReactSVG } from "react-svg";
import useGetContactBoxIcon from "./getImagesHook";
import s from "./ContactBox.module.scss";
const ContactBox = ({ vertical }) => {
    const { phoneIcon, emailIcon, fbIcon } = useGetContactBoxIcon();
    const styles = classnames(s.box, { [s.vertical]: vertical });
    return (<div className={styles}>
      <a className={s.link} href="mailto:info@vgfinc.com" target="_blank" rel="noopener noreferrer">
        <ReactSVG src={emailIcon} className={s.contactIcon}/>
        <span> info@vgfinc.com</span>
      </a>
      <a className={s.link} href="tel:509-865-6422" target="_blank" rel="noopener noreferrer">
        <ReactSVG className={s.contactIcon} src={phoneIcon}/>
        <span>509-865-6422</span>
      </a>
      <a className={s.link} href="https://www.facebook.com/Virgil-Gamache-Farms-Inc-659189977429954/" target="_blank" rel="noopener noreferrer">
        <ReactSVG className={s.contactIcon} src={fbIcon}/>
      </a>
    </div>);
};
export default ContactBox;
