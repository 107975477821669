function debounce(callback, waitTime) {
    let timeNow = Date.now();
    return () => {
        if (timeNow - Date.now() + waitTime < 0) {
            callback();
            timeNow = Date.now();
        }
    };
}
export default debounce;
