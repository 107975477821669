import React from "react";
import Header from "../header";
import DefaultFooter from "../defaultFooter";
import s from "./Layout.module.scss";
import "@styles/index.scss";
const Layout = ({ children, customFooter }) => {
    return (<div className={s.wrap}>
      <Header />
      <div className={s.body}>{children}</div>
      {customFooter ? customFooter() : <DefaultFooter />}
    </div>);
};
export default Layout;
