// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auxiliary-growers-tsx": () => import("./../src/pages/auxiliary-growers.tsx" /* webpackChunkName: "component---src-pages-auxiliary-growers-tsx" */),
  "component---src-pages-best-practices-tsx": () => import("./../src/pages/best-practices.tsx" /* webpackChunkName: "component---src-pages-best-practices-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-farm-history-tsx": () => import("./../src/pages/farm-history.tsx" /* webpackChunkName: "component---src-pages-farm-history-tsx" */),
  "component---src-pages-growing-hops-tsx": () => import("./../src/pages/growing-hops.tsx" /* webpackChunkName: "component---src-pages-growing-hops-tsx" */),
  "component---src-pages-hop-varieties-tsx": () => import("./../src/pages/hop-varieties.tsx" /* webpackChunkName: "component---src-pages-hop-varieties-tsx" */),
  "component---src-pages-hops-for-sale-tsx": () => import("./../src/pages/hops-for-sale.tsx" /* webpackChunkName: "component---src-pages-hops-for-sale-tsx" */),
  "component---src-pages-hoptechnic-tsx": () => import("./../src/pages/hoptechnic.tsx" /* webpackChunkName: "component---src-pages-hoptechnic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-scenes-hop-varieties-template-hop-varieties-template-tsx": () => import("./../src/scenes/hopVarietiesTemplate/HopVarietiesTemplate.tsx" /* webpackChunkName: "component---src-scenes-hop-varieties-template-hop-varieties-template-tsx" */)
}

