import React from "react";
import { Link } from "gatsby";
import ContactBox from "../contactBox";
import useResizeWidth from "../../../../hooks/useResizeWidth";
import { links } from "./data";
import s from "./Sidebar.module.scss";
const mobileScreenWidth = 576;
const Sidebar = ({ handleStateChange, menuOpen }) => {
    const styles = [s.overlay, menuOpen ? s.active : null];
    const { width } = useResizeWidth();
    return (<div className={styles.join(" ")} onClick={handleStateChange}>
      <div className={s.sidebar}>
        <div className={s.linkBox}>
          {links.map(({ link, label }) => label === "Amarillo®" || label === "HopTechnic®" ? (<a className={s.link} href={link} key={link}>
                {label}
              </a>) : (<Link key={link} to={link} className={s.link} activeClassName={s.active}>
                {label}
              </Link>))}
        </div>
        <div className={s.contactBox}>
          {width > mobileScreenWidth && <ContactBox vertical/>}
        </div>
      </div>
    </div>);
};
export default Sidebar;
