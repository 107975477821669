import { useStaticQuery, graphql } from "gatsby";
const useGetContactBoxIcon = () => {
    const { phoneIcon, emailIcon, fbIcon } = useStaticQuery(graphql `
    query {
      fbIcon: file(relativePath: { eq: "images/facebook.svg" }) {
        publicURL
      }

      emailIcon: file(relativePath: { eq: "images/mail.svg" }) {
        publicURL
      }

      phoneIcon: file(relativePath: { eq: "images/phone.svg" }) {
        publicURL
      }
    }
  `);
    return { phoneIcon: phoneIcon.publicURL, emailIcon: emailIcon.publicURL, fbIcon: fbIcon.publicURL };
};
export default useGetContactBoxIcon;
