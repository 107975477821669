import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      salmonSafeImage: file(
        relativePath: { eq: "images/certificates/vgf_SalmonSafe.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      globalGapImage: file(
        relativePath: { eq: "images/certificates/vgf_GlobalGAP.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fdaImage: file(relativePath: { eq: "images/certificates/vgf_FDA.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      certifiedOrganicImage: file(
        relativePath: { eq: "images/certificates/vgf_CertifiedOrganic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kosherImage: file(
        relativePath: { eq: "images/certificates/vgf_Kosher.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hoptech: file(
        relativePath: { eq: "images/certificates/vgf_Hoptech.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      worldwideImage: file(
        relativePath: { eq: "images/certificates/vgf_worldwide.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      truePellet: file(
        relativePath: { eq: "images/certificates/vgf_TruePellet.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return data;
};
