import { useStaticQuery, graphql } from "gatsby";
const useGetLogo = () => {
    const { logo } = useStaticQuery(graphql `
    query {
      logo: file(relativePath: { eq: "images/VGFLogo.png" }) {
        publicURL
      }
    }
  `);
    return { logo: logo.publicURL };
};
export default useGetLogo;
