import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Burger from "./components/burger";
import CertificatesBox from "./components/certificatesBox";
import Sidebar from "./components/sidebar";
import useGetLogo from "./getImagesHook";
import s from "./Header.module.scss";
const headerHeight = 80;
const Header = () => {
    const { logo } = useGetLogo();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isCertificatesOpen, setIsCertificatesOpen] = useState(false);
    const handleSetMenuOpen = () => setMenuOpen(!menuOpen);
    const handleSetMenuCLose = () => setMenuOpen(false);
    const handleCertificatesClick = () => setIsCertificatesOpen(!isCertificatesOpen);
    const headerStyles = classnames(s.header, {
        [s.active]: isScrolled,
        [s.headerIndex]: isCertificatesOpen,
    });
    const handler = () => setIsScrolled(globalThis.pageYOffset > headerHeight);
    useEffect(() => {
        document.addEventListener("scroll", handler);
        return () => document.removeEventListener("scroll", handler);
    }, []);
    return (<>
      <Burger handleStateChange={handleSetMenuOpen} menuOpen={menuOpen}/>
      <header className={headerStyles}>
        <div className={s.content}>
          <div className={s.logo}>
            <img src={logo} className={s.logoImage} alt="vfg inc"/>
          </div>
          <div className={s.modalWrap}>
            <CertificatesBox isCertificatesOpen={isCertificatesOpen} handleCertificatesClick={handleCertificatesClick}/>
          </div>
        </div>
      </header>
      <Sidebar menuOpen={menuOpen} handleStateChange={handleSetMenuCLose}/>
    </>);
};
export default Header;
