import React from "react";
import useGetContactBoxIcon from "./getImagesHook";
import { ReactSVG } from "react-svg";
import s from "./DefaultFooter.module.scss";
export default () => {
    const { emailIcon, fbIcon, instaIcon } = useGetContactBoxIcon();
    return (<footer>
      <div className={s.footer}>
        <div className={s.content}>
          Virgil Gamache Farms, Toppenish, Washington{" "}
          <span className={s.separator}>|</span>
          <div className={s.contacts}>
            <a className={s.link} href="tel:509-865-6422" target="_blank" rel="noopener noreferrer">
              509-865-6422
            </a>
            <a className={s.link} href="mailto:info@vgfinc.com" target="_blank" rel="noopener noreferrer">
              <ReactSVG src={emailIcon} className={s.contactIcon}/>
              <span> info@vgfinc.com</span>
            </a>
            <a className={s.link} href="https://www.facebook.com/Virgil-Gamache-Farms-Inc-659189977429954/" target="_blank" rel="noopener noreferrer">
              <ReactSVG className={s.contactIcon} src={fbIcon}/>
            </a>
            <a className={s.link} href="https://www.instagram.com/virgil_gamache_farms/" target="_blank" rel="noopener noreferrer">
              <ReactSVG className={s.contactIcon} src={instaIcon}/>
            </a>
          </div>
        </div>
      </div>
    </footer>);
};
